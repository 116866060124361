<template>
  <BaseItemForm
    v-slot="{ cancel, itemIsNew }"
    :category="category"
    :item="item"
  >
    <b-row align-v="center">
      <b-col>
        <b-row>
          <b-col>
            <p class="form-control-label">
              <strong v-if="itemIsNew(item)">Ajouter l'article</strong>
              <strong v-else>Modifier l'article</strong>
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-media no-body class="align-items-center">
              <ProductThumbnail :product="product" />
              <b-media-body>
                <p v-if="product.vigieId" class="h6 text-muted mb-0">
                  <span>Code produit: {{ product.vigieId }}</span>
                </p>
                <p
                  v-if="product.isbn13 || product.isbn10"
                  class="h6 text-muted mb-0"
                >
                  <span>ISBN: {{ product.isbn13 || product.isbn10 }}</span>
                </p>
                <p class="h5 mb-0">
                  {{ product.name }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <BaseButton type="default" size="sm" native-type="submit">
              <span class="btn-inner--text">{{
                itemIsNew(item) ? 'Ajouter l\’article' : 'Enregistrer'
              }}</span>
            </BaseButton>

            <BaseButton type="secondary" size="sm" @click.prevent="cancel">
              <span class="btn-inner--text">Annuler</span>
            </BaseButton>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </BaseItemForm>
</template>
<script>
import { BaseButton } from '@coop-zone/coop-zone-argon'
import BaseItemForm from '@/components/SchoolLists/Items/BaseItemForm'
import ProductThumbnail from '@/components/Product/ProductThumbnail'

export default {
  components: {
    BaseButton,
    BaseItemForm,
    ProductThumbnail,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  computed: {
    product() {
      return this.item.products[0]
    },
    item() {
      const index = this.category.ListCategoryItems.findIndex(
        (item) => item.id === this.itemId
      )
      return this.category.ListCategoryItems[index]
    },
  },
}
</script>
