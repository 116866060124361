import { uniqBy, groupBy } from 'lodash'
import { apolloClient } from '@/apollo'
import refunds from './refunds.module'

const ordersSummaryQuery = require('@/apollo/queries/GetOrdersSummary.gql')
const ordersQuery = require('@/apollo/queries/GetOrders.gql')
const orderQuery = require('@/apollo/queries/GetOrder.gql')
const printOrdersMutation = require('@/apollo/mutations/PrintOrders.gql')
const orderJSONQuery = require('@/apollo/queries/GetOrderJSON.gql')
const UpdateOrdersReceptionBoxMutation = require('@/apollo/mutations/UpdateOrdersReceptionBox.gql')

const state = {
  statuses: [
    { id: 'VOID', name: 'Annulée' },
    { id: 'COMPLETED', name: 'Complétée' },
    { id: 'PROCESSING', name: 'En traitement' },
    { id: 'NEW', name: 'Nouvelle' },
    { id: 'REFUNDED', name: 'Remboursée' },
    { id: 'INCOMPLETE', name: 'Incomplète' },
    { id: 'AWAITING_PAYMENT', name: 'En attente de paiement' },
  ],
  batchActionIds: [],
  ordersMap: {},
  typesIcones: {
    Book: 'fa fa-book',
    DigitalBook: 'fa fa-tablet',
    Computer: 'fa fa-laptop',
    Art: 'fa fa-paint-brush',
    OfficeSupply: 'fa fa-building',
    CourseNote: 'fa fa-graduation-cap',
    DigitalProduct: 'fa fa-file',
  },
  order: {
    id: null,
    date: null,
    products: [],
    taxes: [],
    shippingFees: null,
    handlingFees: null,
    subTotal: null,
    total: null,
    shippingAddress: null,
    billingAddress: null,
    payment: null,
    pickUp: null,
    childFirstName: null,
    childLastName: null,
    businessOrderNumber: null,
    actionRequired: false,
  },
  manifests: [],
  processingItems: [],
  searchOrders: [],
  pagination: {
    currentPage: 1,
    numberOfPages: 0,
    resultsPerPage: 100,
    totalCount: 0,
  },
}

const actions = {
  async getOrder({ commit }, id) {
    const {
      data: { order },
    } = await apolloClient.query({
      query: orderQuery,
      variables: { id },
    })

    commit('SET_ORDER', order)
    commit('SET_ORDERS_MAP', [order])
    commit('SET_PROCESSING_ITEMS', order.completeTracking.processing)
    commit('SET_MANIFESTS', order.completeTracking.manifests)
  },

  async getSearchOrders({ commit }, payload) {
    const { statuses } = payload
    delete payload.statuses
    if (statuses) {
      payload.statuses = [statuses]
    } else {
      payload.statuses = [
        'VOID',
        'COMPLETED',
        'PROCESSING',
        'NEW',
        'REFUNDED',
        'INCOMPLETE',
      ]
    }

    const {
      data: {
        orders: { orders, pageInfo: pagination },
      },
    } = await apolloClient.query({
      query: ordersSummaryQuery,
      variables: payload,
    })

    commit('SET_PAGINATION', pagination)
    commit('SET_SEARCH_ORDERS', orders)
  },

  async getOrders({ commit, getters }, { ids }) {
    const orderIds = getters.getOrders.map((order) => order.id)
    const fetchOrderIds = ids.filter((x) => !orderIds.includes(x))

    if (fetchOrderIds.length > 0) {
      const {
        data: {
          orders: { orders },
        },
      } = await apolloClient.query({
        query: ordersQuery,
        variables: {
          ids: fetchOrderIds,
          limit: fetchOrderIds.length,
        },
      })

      commit('SET_ORDERS_MAP', orders)
    }
  },

  async updateReceptionBox(context, { ids: orderIds, receptionBoxId }) {
    await apolloClient.query({
      query: UpdateOrdersReceptionBoxMutation,
      variables: { orderIds: orderIds, id: receptionBoxId },
    })
  },

  async printOrders(context, { ids, status }) {
    await apolloClient.query({
      query: printOrdersMutation,
      variables: { ids, print: status },
    })
  },

  async orderJSON(context, { id }) {
    const retVal = await apolloClient.query({
      query: orderJSONQuery,
      variables: { id },
    })
    return retVal
  },
}

const mutations = {
  SET_SEARCH_ORDERS(state, payload) {
    state.searchOrders = payload
  },

  SET_ORDERS_MAP(state, payload) {
    const orders = payload.reduce(
      (acc, order) => ({ ...acc, [order.id]: order }),
      {}
    )
    state.ordersMap = { ...state.ordersMap, ...orders }
  },

  SET_BATCH_ACTION_IDS(state, payload) {
    state.batchActionIds = payload
  },

  SET_ORDER(state, payload) {
    state.order = payload
  },

  SET_MANIFESTS(state, payload) {
    state.manifests = payload
  },

  SET_PROCESSING_ITEMS(state, payload) {
    state.processingItems = payload
  },

  SET_PAGINATION(state, payload) {
    state.pagination = payload
  },
}

const getters = {
  getOrders: (state) => Object.values(state.ordersMap),
  getStatusName: (state) => (statusId) =>
    state.statuses.find((status) => status.id === statusId).name,
  getSingleProducts: () => (orderProducts) =>
    orderProducts.filter(
      (product) =>
        product.productData.type !== 'Bundle' &&
        product.productData.type !== 'Membership'
    ),

  getBundleGroupedProducts: () => (orderProducts) => {
    if (!orderProducts || orderProducts.length === 0) return null
    const productGroups = groupBy(orderProducts, 'BundleItemId')
    const products = orderProducts.filter(
      (product) => product.BundleItemId === null
    )

    const result = products.map((product) => {
      if (!productGroups[product.id]) return product

      const group = { ...product, products: productGroups[product.id] }
      return productGroups[product.id][0].statuses
        ? { ...group, statuses: productGroups[product.id][0].statuses }
        : group
    })
    return result
  },
  getBatchActionOrders: (state) =>
    Object.values(state.ordersMap).filter((order) =>
      state.batchActionIds.includes(order.id)
    ),
  getBundleNameById: (state) => (order, bundleId) => {
    const bundle = order.products.find((product) => product.id === bundleId)
    return bundle ? bundle.name : ''
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    refunds,
  },
}
