<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">
            Établissements scolaires
          </p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h1 class="h3 mb-0">Liste des établissements scolaires</h1>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/establishments/schools/add"
              data-cy="establishment-add-link"
            >
              Ajouter un établissement
            </router-link>
          </b-col>
        </b-row>

        <FuseList :data="schoolEstablishments" :keys="['id', 'name', 'type']">
          <el-table-column
            label="Nom de l'établissement"
            min-width="160px"
            sortable
            sort-by="name"
            :sort-method="(a, b) => a.name.localeCompare(b.name)"
          >
            <template slot-scope="scope">
              <router-link :to="`/establishments/schools/edit/${scope.row.id}`">
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column
            label="Type d'établissement"
            min-width="160px"
            sortable
            sort-by="type"
            :sort-method="(a, b) => a.type.localeCompare(b.type)"
          >
            <template slot-scope="scope">
              {{ $t(`establishments.types.${scope.row.type}`) }}
            </template>
          </el-table-column>

          <el-table-column label="Slug" min-width="160px" prop="slug">
          </el-table-column>

          <el-table-column min-width="100px" align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/establishments/schools/edit/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState, mapGetters } from 'vuex'
import FuseList from '@/components/FuseList'

export default {
  components: {
    BaseHeader,
    FuseList,
    RouteBreadcrumb,
  },
  computed: {
    ...mapState('schoolEstablishments', ['schoolEstablishments']),
  },
  created() {
    this.$store.dispatch('schoolEstablishments/getSchoolEstablishments', [
      'BUSINESS',
      'ELEMENTARY',
      'HIGHSCHOOL',
      'COLLEGE',
      'UNIVERSITY',
    ])
  },
}
</script>
