var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"logo":"/img/brand/branding-coopzone.svg"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Tableau de bord',
          path: '/dashboard',
          icon: 'fas fa-home',
        }}}),(_vm.account && _vm.roles[_vm.account.role].includes('CATALOG'))?_c('sidebar-item',{attrs:{"data-cy":"menu-catalog","link":{
          name: 'Catalogue',
          icon: 'fas fa-layer-group',
        }}},[(_vm.account && _vm.roles[_vm.account.role].includes('CATALOG'))?_c('sidebar-item',{attrs:{"data-cy":"search-product-link","link":{ name: 'Produits', path: '/catalog/search' }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('CATALOG'))?_c('sidebar-item',{attrs:{"data-cy":"search-product-link","link":{ name: 'Ensembles', path: '/catalog/bundles' }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('CATALOG'))?_c('sidebar-item',{attrs:{"link":{ name: 'Catégories', path: '/categories/index' },"data-cy":"search-category-link"}}):_vm._e()],1):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('ORDERS'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Commandes',
          icon: 'ni ni-shop',
        }}},[(_vm.account && _vm.roles[_vm.account.role].includes('ORDERS'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Liste des commandes',
            path: '/orders/search',
          }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('ORDERS'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Remboursements',
            path: '/orders/refunds',
          }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('ORDERS'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Boîtes de réception',
            path: '/reception-boxes/index',
          }}}):_vm._e()],1):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('FILTERS'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Filtres',
          icon: 'ni ni-ui-04',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Catégories de filtres',
            path: '/filters/groups/index',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Filtres',
            path: '/filters/index',
          }}})],1):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('ACCOUNTS'))?_c('sidebar-item',{attrs:{"data-cy":"search-user-link","link":{
          name: 'Utilisateurs',
          path: '/users/search',
          icon: 'ni ni-single-02',
        }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('PAGES'))?_c('sidebar-item',{attrs:{"data-cy":"menu-cms","link":{
          name: 'CMS',
          icon: 'ni ni-single-copy-04',
          path: '/pages/index',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Pages', path: '/pages/index' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Landing pages', path: '/landing-pages/index' }}})],1):_vm._e(),(
          _vm.account &&
          (_vm.roles[_vm.account.role].includes('SHIPPING') ||
            _vm.roles[_vm.account.role].includes('TAXES'))
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Localisation',
          icon: 'fas fa-globe-americas',
        },"data-cy":"menu-localization"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Zones', path: '/localisation/zones/index' },"data-cy":"zone-index-link"}}),_c('sidebar-item',{attrs:{"link":{ name: 'Pays', path: '/localisation/countries/index' },"data-cy":"country-index-link"}}),_c('sidebar-item',{attrs:{"link":{ name: 'Provinces', path: '/localisation/states/index' },"data-cy":"state-index-link"}})],1):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('TAXES'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Règles de taxes',
          icon: 'ni ni-money-coins',
        },"data-cy":"menu-tax"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Taxes', path: '/taxes_rules/taxes/index' },"data-cy":"tax-index-link"}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Groupes de règles',
            path: '/taxes_rules/groups/index',
          },"data-cy":"taxrule-index-link"}})],1):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('SHIPPING'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Transport',
          icon: 'ni ni-delivery-fast',
        },"data-cy":"menu-delivery"}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Frais de manutention',
            path: '/shipping/handling_fees/edit',
          },"data-cy":"handlingfee-edit-link"}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Frais de transport',
            path: '/shipping/delivery_rules/index',
          },"data-cy":"deliveryrule-index-link"}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Transporteurs',
            path: '/shipping/transporters/index',
          }}})],1):_vm._e(),(
          _vm.account &&
          _vm.roles[_vm.account.role].includes('SCHOOLSUPPLY') &&
          !['TEACHER', 'SCHOOL'].includes(_vm.account.role)
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Établissements',
          icon: 'ni ni-building',
        },"data-cy":"menu-establishment"}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Succursales',
            path: '/establishments/stores/index',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Points de cueillette',
            path: '/establishments/pickingpoints/index',
          }}}),(_vm.account && _vm.roles[_vm.account.role].includes('SCHOOLSUPPLY'))?_c('sidebar-item',{attrs:{"link":{ name: 'Scolaires', path: '/establishments/schools/index' },"data-cy":"establishment-index-link"}}):_vm._e()],1):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('SCHOOLSUPPLY'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Materiel scolaire',
          icon: 'fas fa-graduation-cap',
        }}},[(
            _vm.account &&
            _vm.roles[_vm.account.role].includes('SCHOOLSUPPLY') &&
            _vm.account.role !== 'TEACHER'
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'Primaire/Secondaire',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Listes scolaires',
              path: '/school-lists/type/grade/index',
            },"data-cy":"menu-schoolsupply"}})],1):_vm._e(),(
            _vm.account &&
            _vm.roles[_vm.account.role].includes('SCHOOLSUPPLY') &&
            _vm.account.role !== 'SCHOOL'
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'Collegial/Universitaire',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Listes scolaires',
              path: '/school-lists/type/course/index',
            }}}),(
              _vm.account &&
              _vm.roles[_vm.account.role].includes('SCHOOLSUPPLY') &&
              !['TEACHER', 'SCHOOL'].includes(_vm.account.role)
            )?_c('sidebar-item',{attrs:{"link":{
              name: 'Sections de cours',
              path: '/school-lists/sections/index',
            }}}):_vm._e()],1):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('OFFICESUPPLY'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Programmes de vente d\'ordinateurs',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Programmes',
              path: '/school-lists/computer-sales-programs/index',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Listes de sous-programmes',
              path: '/school-lists/type/program/index',
            }}})],1):_vm._e(),(
            _vm.account &&
            _vm.roles[_vm.account.role].includes('SCHOOLSUPPLY') &&
            _vm.account.role !== 'TEACHER'
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'Entreprise',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Listes pour clients corporatifs',
              path: '/school-lists/type/business/index',
            },"data-cy":"menu-schoolsupply"}})],1):_vm._e(),(
            _vm.account &&
            _vm.roles[_vm.account.role].includes('SCHOOLSUPPLY') &&
            !['TEACHER', 'SCHOOL'].includes(_vm.account.role)
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'Sessions',
            path: '/school-lists/sessions/index',
          }}}):_vm._e()],1):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('CATALOG'))?_c('sidebar-item',{attrs:{"data-cy":"promotions-link","link":{
          name: 'Promotions',
          path: '/promotions',
          icon: 'fas fa-bullhorn',
        }}},[(_vm.account && _vm.roles[_vm.account.role].includes('CATALOG'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Appels à l\'action',
            path: '/promotions/call-to-actions/index',
          }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('CATALOG'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Codes promo',
            path: '/promotions/codes/index',
          }}}):_vm._e()],1):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('REPORTS'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Exportations',
          path: '/exports',
          icon: 'fas fa-cloud-download-alt',
        }}},[(_vm.account && _vm.roles[_vm.account.role].includes('REPORTS'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Produits',
            path: '/exports/products/index',
          }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('REPORTS'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Catégories',
            path: '/exports/categories/index',
          }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('REPORTS'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Utilisateurs',
            path: '/exports/users/index',
          }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('REPORTS'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Commandes',
            path: '/exports/orders/index',
          }}}):_vm._e(),(_vm.account && _vm.roles[_vm.account.role].includes('REPORTS'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Listes scolaires',
            path: '/exports/school-lists/index',
          }}}):_vm._e()],1):_vm._e()],1)],2),_c('div',{staticClass:"main-content"},[_c('layout-navbar',{attrs:{"type":_vm.$route.name === 'dashboard' ? 'light' : 'default'}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view',{key:(_vm.$route.name || '') + (_vm.$route.params.type || '')})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }