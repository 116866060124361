import {
  BaseHeader,
  RouteBreadcrumb,
  BaseInput,
} from '@coop-zone/coop-zone-argon'
import { mapState, mapGetters } from 'vuex'
import { isEqual } from 'lodash'
import BaseTableSearch from '@/components/BaseTableSearch'
import SchoolSelect from '@/components/Inputs/SchoolSelect'
import SchoolListsStatusBadge from '@/components/SchoolLists/SchoolListsStatusBadge'
import SchoolListStatusSelect from '@/components/Inputs/SchoolListStatusSelect'
import SchoolSessionSelect from '@/components/Inputs/SchoolSessionSelect'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'
import SchoolListsExportButton from '@/components/SchoolLists/SchoolListsExportButton'

import { canDelete } from '@/abilities/schoolLists'

export default {
  components: {
    BaseInput,
    BaseHeader,
    BaseTableSearch,
    RouteBreadcrumb,
    SchoolSelect,
    SchoolListsStatusBadge,
    SchoolListStatusSelect,
    SchoolSessionSelect,
    SchoolListsExportButton,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      schoolId: null,
      statusId: null,
      sessionId: null,
      term: '',
      page: 1,
      type: null,
    }
  },
  computed: {
    ...mapState('accounts', ['account']),
    ...mapState('schoolLists', ['pagination', 'schoolLists']),
    ...mapGetters('schoolEstablishments', [
      'getSchoolEstablishmentTypesBySchoolListType',
    ]),
    schoolListAddUrl() {
      const school = this.schoolId !== null ? `/${this.schoolId}` : ''
      return `/school-lists/type/${this.type}/add${school}`
    },
    schoolSessionsEstablishmentTypes() {
      switch (this.type) {
        case 'program':
          return ['ELEMENTARY', 'HIGHSCHOOL', 'COLLEGE', 'UNIVERSITY']
        case 'course':
          return ['COLLEGE', 'UNIVERSITY']
        case 'grade':
          return ['ELEMENTARY', 'HIGHSCHOOL']
        case 'BUSINESS':
          return ['BUSINESS']
        default:
          return null
      }
    },
  },
  watch: {
    statusId() {
      this.search(this.term, 1)
    },
    sessionId() {
      this.search(this.term, 1)
    },
    schoolId() {
      this.search(this.term, 1)
    },
  },
  created() {
    this.$store.dispatch('schoolLists/init')
    this.term = this.$route.query.term ? this.$route.query.term : this.term
    this.statusId = this.$route.query.statusId
      ? this.$route.query.statusId
      : this.statusId
    this.sessionId = this.$route.query.sessionId
      ? this.$route.query.sessionId
      : this.sessionId
    this.schoolId = this.$route.query.establishmentId
      ? this.$route.query.establishmentId
      : this.schoolId
    this.search(this.term, 1)
  },
  methods: {
    schoolListEditUrl(list) {
      return `/school-lists/type/${this.type}/edit/${list.EstablishmentId}/${list.id}`
    },

    async search(searchTerm, page = 1) {
      this.term = searchTerm
      this.page = page
      try {
        await this.$store.dispatch('schoolLists/searchSchoolLists', {
          search: this.term.length >= 3 ? this.term : '',
          types: this.getSchoolEstablishmentTypesBySchoolListType(this.type),
          EstablishmentId: this.schoolId,
          statusId: this.statusId,
          sessionId: this.sessionId,
          page: page,
          limit: this.pagination.resultsPerPage,
          sort: this.sortObject,
        })

        const query = {}
        if (this.term) query.term = this.term
        if (this.statusId) query.statusId = this.statusId
        if (this.sessionId) query.sessionId = this.sessionId
        if (this.schoolId) query.establishmentId = this.schoolId

        if (isEqual(query, this.$route.query) == false) {
          this.$router.replace({ query })
        }
      } catch (error) {
        this.handleError(error)
      }
    },

    sortMethod({ prop, order }) {
      if (order) {
        this.sortObject = {
          column: prop,
          order: order === 'descending' ? 'DESC' : 'ASC',
        }
      } else {
        this.sortObject = null
      }

      this.search(this.term, this.page)
    },

    async duplicateSchoolList(schoolList) {
      await this.$store.dispatch('schoolLists/duplicateSchoolList', schoolList)
      this.search(this.term, this.page)
    },

    async deleteSchoolList(schoolList) {
      try {
        await this.$store.dispatch('schoolLists/deleteSchoolList', schoolList)
        this.search(this.term, this.page)
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },

    canDelete,
  },
}
