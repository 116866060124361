<template>
  <component
    :is="form"
    v-if="itemIsBeingEdited(item)"
    :category="category"
    :item-id="item.id"
  />
  <b-row v-else>
    <b-col cols="9">
      <component
        :is="listItem"
        :category="category"
        :item-id="item.id"
        :readonly="disabled"
      />
    </b-col>

    <b-col v-if="!disabled" align="end">
      <a
        href="#!"
        class="table-action table-action-delete"
        @click="removeItem({ category, item })"
      >
        <i class="fas fa-trash" />
      </a>

      <a
        href="#!"
        class="table-action table-action-edit ml-2"
        data-toggle="tooltip"
        data-original-title="Modifier"
        @click.prevent="SET_ITEM_EDIT_STATE({ item, editState: true })"
      >
        <i class="fa fa-edit" />
      </a>

      <a href="#!" class="table-action table-action-edit ml-2 item-handle">
        <i class="fas fa-arrows-alt" />
      </a>
    </b-col>
  </b-row>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ProductItem: () =>
      import('@/components/SchoolLists/Items/Product/ProductItem'),
    GroupItem: () => import('@/components/SchoolLists/Items/Group/GroupItem'),
    OtherItem: () => import('@/components/SchoolLists/Items/Other/OtherItem'),
    ProgramProductItemForm: () =>
      import('@/components/SchoolLists/Items/Product/ProgramProductItemForm'),
    ProgramGroupItemForm: () =>
      import('@/components/SchoolLists/Items/Group/ProgramGroupItemForm'),
    CourseProductItemForm: () =>
      import('@/components/SchoolLists/Items/Product/CourseProductItemForm'),
    CourseGroupItemForm: () =>
      import('@/components/SchoolLists/Items/Group/CourseGroupItemForm'),
    CourseOtherItemForm: () =>
      import('@/components/SchoolLists/Items/Other/CourseOtherItemForm'),
    GradeProductItemForm: () =>
      import('@/components/SchoolLists/Items/Product/GradeProductItemForm'),
    GradeGroupItemForm: () =>
      import('@/components/SchoolLists/Items/Group/GradeGroupItemForm'),
    GradeOtherItemForm: () =>
      import('@/components/SchoolLists/Items/Other/GradeOtherItemForm'),
    BusinessProductItemForm: () =>
      import('@/components/SchoolLists/Items/Product/BusinessProductItemForm'),
    BusinessGroupItemForm: () =>
      import('@/components/SchoolLists/Items/Group/BusinessGroupItemForm'),
    BusinessOtherItemForm: () =>
      import('@/components/SchoolLists/Items/Other/BusinessOtherItemForm'),
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('schoolLists/categories', ['itemIsBeingEdited']),
    schoolType() {
      return this.componentify(this.$route.params.type)
    },
    itemType() {
      return this.componentify(this.item.type)
    },
    form() {
      return `${this.schoolType}${this.itemType}ItemForm`
    },
    listItem() {
      return `${this.itemType}Item`
    },
  },
  methods: {
    componentify(value) {
      const lowerCase = value.toLowerCase()
      return `${lowerCase.charAt(0).toUpperCase()}${lowerCase.slice(1)}`
    },
    ...mapActions('schoolLists/categories', ['removeItem']),
    ...mapMutations('schoolLists/categories', ['SET_ITEM_EDIT_STATE']),
  },
}
</script>
