export default {
  memberType: {
    regular: 'Régulier',
    member: 'Membre',
    employee: 'Employé',
    institute: 'Institut',
    business: 'Corporatif',
    emerging_artist: 'Artiste émergent',
    professional_artist: 'Artiste professionnel',
    library: 'Librairie',
    cjms: 'CJMS',
    special_1: 'Spécial 1',
    special_2: 'Spécial 2',
    special_3: 'Spécial 3',
    special_4: 'Spécial 4',
  },
}
