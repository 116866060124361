<template>
  <div class="wrapper">
    <notifications />
    <side-bar logo="/img/brand/branding-coopzone.svg">
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Tableau de bord',
            path: '/dashboard',
            icon: 'fas fa-home',
          }"
        />
        <sidebar-item
          v-if="account && roles[account.role].includes('CATALOG')"
          data-cy="menu-catalog"
          :link="{
            name: 'Catalogue',
            icon: 'fas fa-layer-group',
          }"
        >
          <sidebar-item
            v-if="account && roles[account.role].includes('CATALOG')"
            data-cy="search-product-link"
            :link="{ name: 'Produits', path: '/catalog/search' }"
          />
          <sidebar-item
            v-if="account && roles[account.role].includes('CATALOG')"
            data-cy="search-product-link"
            :link="{ name: 'Ensembles', path: '/catalog/bundles' }"
          />

          <sidebar-item
            v-if="account && roles[account.role].includes('CATALOG')"
            :link="{ name: 'Catégories', path: '/categories/index' }"
            data-cy="search-category-link"
          />
        </sidebar-item>
        <sidebar-item
          v-if="account && roles[account.role].includes('ORDERS')"
          :link="{
            name: 'Commandes',
            icon: 'ni ni-shop',
          }"
        >
          <sidebar-item
            v-if="account && roles[account.role].includes('ORDERS')"
            :link="{
              name: 'Liste des commandes',
              path: '/orders/search',
            }"
          />
          <sidebar-item
            v-if="account && roles[account.role].includes('ORDERS')"
            :link="{
              name: 'Remboursements',
              path: '/orders/refunds',
            }"
          />
          <sidebar-item
            v-if="account && roles[account.role].includes('ORDERS')"
            :link="{
              name: 'Boîtes de réception',
              path: '/reception-boxes/index',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="account && roles[account.role].includes('FILTERS')"
          :link="{
            name: 'Filtres',
            icon: 'ni ni-ui-04',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Catégories de filtres',
              path: '/filters/groups/index',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Filtres',
              path: '/filters/index',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="account && roles[account.role].includes('ACCOUNTS')"
          data-cy="search-user-link"
          :link="{
            name: 'Utilisateurs',
            path: '/users/search',
            icon: 'ni ni-single-02',
          }"
        />

        <sidebar-item
          v-if="account && roles[account.role].includes('PAGES')"
          data-cy="menu-cms"
          :link="{
            name: 'CMS',
            icon: 'ni ni-single-copy-04',
            path: '/pages/index',
          }"
        >
          <sidebar-item :link="{ name: 'Pages', path: '/pages/index' }" />
          <sidebar-item
            :link="{ name: 'Landing pages', path: '/landing-pages/index' }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="
            account &&
            (roles[account.role].includes('SHIPPING') ||
              roles[account.role].includes('TAXES'))
          "
          :link="{
            name: 'Localisation',
            icon: 'fas fa-globe-americas',
          }"
          data-cy="menu-localization"
        >
          <sidebar-item
            :link="{ name: 'Zones', path: '/localisation/zones/index' }"
            data-cy="zone-index-link"
          />
          <sidebar-item
            :link="{ name: 'Pays', path: '/localisation/countries/index' }"
            data-cy="country-index-link"
          />
          <sidebar-item
            :link="{ name: 'Provinces', path: '/localisation/states/index' }"
            data-cy="state-index-link"
          />
        </sidebar-item>
        <sidebar-item
          v-if="account && roles[account.role].includes('TAXES')"
          :link="{
            name: 'Règles de taxes',
            icon: 'ni ni-money-coins',
          }"
          data-cy="menu-tax"
        >
          <sidebar-item
            :link="{ name: 'Taxes', path: '/taxes_rules/taxes/index' }"
            data-cy="tax-index-link"
          />
          <sidebar-item
            :link="{
              name: 'Groupes de règles',
              path: '/taxes_rules/groups/index',
            }"
            data-cy="taxrule-index-link"
          />
        </sidebar-item>
        <sidebar-item
          v-if="account && roles[account.role].includes('SHIPPING')"
          :link="{
            name: 'Transport',
            icon: 'ni ni-delivery-fast',
          }"
          data-cy="menu-delivery"
        >
          <sidebar-item
            :link="{
              name: 'Frais de manutention',
              path: '/shipping/handling_fees/edit',
            }"
            data-cy="handlingfee-edit-link"
          />
          <sidebar-item
            :link="{
              name: 'Frais de transport',
              path: '/shipping/delivery_rules/index',
            }"
            data-cy="deliveryrule-index-link"
          />
          <sidebar-item
            :link="{
              name: 'Transporteurs',
              path: '/shipping/transporters/index',
            }"
          />
        </sidebar-item>
        <sidebar-item
          v-if="
            account &&
            roles[account.role].includes('SCHOOLSUPPLY') &&
            !['TEACHER', 'SCHOOL'].includes(account.role)
          "
          :link="{
            name: 'Établissements',
            icon: 'ni ni-building',
          }"
          data-cy="menu-establishment"
        >
          <sidebar-item
            :link="{
              name: 'Succursales',
              path: '/establishments/stores/index',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Points de cueillette',
              path: '/establishments/pickingpoints/index',
            }"
          />
          <sidebar-item
            v-if="account && roles[account.role].includes('SCHOOLSUPPLY')"
            :link="{ name: 'Scolaires', path: '/establishments/schools/index' }"
            data-cy="establishment-index-link"
          />
        </sidebar-item>

        <sidebar-item
          v-if="account && roles[account.role].includes('SCHOOLSUPPLY')"
          :link="{
            name: 'Materiel scolaire',
            icon: 'fas fa-graduation-cap',
          }"
        >
          <sidebar-item
            v-if="
              account &&
              roles[account.role].includes('SCHOOLSUPPLY') &&
              account.role !== 'TEACHER'
            "
            :link="{
              name: 'Primaire/Secondaire',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Listes scolaires',
                path: '/school-lists/type/grade/index',
              }"
              data-cy="menu-schoolsupply"
            />
          </sidebar-item>
          <sidebar-item
            v-if="
              account &&
              roles[account.role].includes('SCHOOLSUPPLY') &&
              account.role !== 'SCHOOL'
            "
            :link="{
              name: 'Collegial/Universitaire',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Listes scolaires',
                path: '/school-lists/type/course/index',
              }"
            />

            <sidebar-item
              v-if="
                account &&
                roles[account.role].includes('SCHOOLSUPPLY') &&
                !['TEACHER', 'SCHOOL'].includes(account.role)
              "
              :link="{
                name: 'Sections de cours',
                path: '/school-lists/sections/index',
              }"
            />
          </sidebar-item>

          <sidebar-item
            v-if="account && roles[account.role].includes('OFFICESUPPLY')"
            :link="{
              name: 'Programmes de vente d\'ordinateurs',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Programmes',
                path: '/school-lists/computer-sales-programs/index',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Listes de sous-programmes',
                path: '/school-lists/type/program/index',
              }"
            />
          </sidebar-item>

          <sidebar-item
            v-if="
              account &&
              roles[account.role].includes('SCHOOLSUPPLY') &&
              account.role !== 'TEACHER'
            "
            :link="{
              name: 'Entreprise',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Listes pour clients corporatifs',
                path: '/school-lists/type/business/index',
              }"
              data-cy="menu-schoolsupply"
            />
          </sidebar-item>

          <sidebar-item
            v-if="
              account &&
              roles[account.role].includes('SCHOOLSUPPLY') &&
              !['TEACHER', 'SCHOOL'].includes(account.role)
            "
            :link="{
              name: 'Sessions',
              path: '/school-lists/sessions/index',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="account && roles[account.role].includes('CATALOG')"
          data-cy="promotions-link"
          :link="{
            name: 'Promotions',
            path: '/promotions',
            icon: 'fas fa-bullhorn',
          }"
        >
          <sidebar-item
            v-if="account && roles[account.role].includes('CATALOG')"
            :link="{
              name: 'Appels à l\'action',
              path: '/promotions/call-to-actions/index',
            }"
          />
          <sidebar-item
            v-if="account && roles[account.role].includes('CATALOG')"
            :link="{
              name: 'Codes promo',
              path: '/promotions/codes/index',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="account && roles[account.role].includes('REPORTS')"
          :link="{
            name: 'Exportations',
            path: '/exports',
            icon: 'fas fa-cloud-download-alt',
          }"
        >
          <sidebar-item
            v-if="account && roles[account.role].includes('REPORTS')"
            :link="{
              name: 'Produits',
              path: '/exports/products/index',
            }"
          />

          <sidebar-item
            v-if="account && roles[account.role].includes('REPORTS')"
            :link="{
              name: 'Catégories',
              path: '/exports/categories/index',
            }"
          />

          <sidebar-item
            v-if="account && roles[account.role].includes('REPORTS')"
            :link="{
              name: 'Utilisateurs',
              path: '/exports/users/index',
            }"
          />

          <sidebar-item
            v-if="account && roles[account.role].includes('REPORTS')"
            :link="{
              name: 'Commandes',
              path: '/exports/orders/index',
            }"
          />

          <sidebar-item
            v-if="account && roles[account.role].includes('REPORTS')"
            :link="{
              name: 'Listes scolaires',
              path: '/exports/school-lists/index',
            }"
          />
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <layout-navbar
        :type="$route.name === 'dashboard' ? 'light' : 'default'"
      />

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view
            :key="($route.name || '') + ($route.params.type || '')"
          />
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions'
import { mapState } from 'vuex'
import LayoutNavbar from '@/components/Navbar'

export default {
  components: { LayoutNavbar, FadeTransition },
  computed: {
    ...mapState('accounts', ['account']),
    ...mapState('permissions', ['roles']),
  },
}
</script>
