export default {
  types: {
    BUSINESS: 'Corporatif',
    ELEMENTARY: 'Primaire',
    HIGHSCHOOL: 'Secondaire',
    COLLEGE: 'Collégial',
    UNIVERSITY: 'Universitaire',
    PROGRAM: "Programmes d'achat d'ordinateurs",
  },
}
