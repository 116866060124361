<template>
  <b-card no-body>
    <div slot="header">
      <b-row>
        <b-col>
          <p class="h3">Liste des articles pour chacune des sections</p>
        </b-col>
      </b-row>
    </div>

    <el-table
      :data="sortedCategories"
      class="table-responsive table-flush table-striped no-break"
      header-row-class-name="thead-light"
      header-cell-class-name="pl-4 pr-4"
      :cell-class-name="handleCellClassName"
    >
      <el-table-column
        label="Matières"
        class-name="p-0 align-top"
        min-width="400px"
      >
        <template slot-scope="{ row }">
          <b-row class="p-4">
            <b-col cols="6">
              {{ row.name }}
            </b-col>
          </b-row>
        </template>
      </el-table-column>

      <el-table-column
        label="Articles"
        class-name="p-0 align-top"
        min-width="400px"
      >
        <template slot-scope="{ row }">
          <ItemsList class="p-4" :disabled="true" :category="row" />
        </template>
      </el-table-column>

      <el-table-column
        label="Suggestions d'articles supplémentaires"
        class-name="p-0 align-top"
        min-width="400px"
      >
        <template slot-scope="{ row }">
          <b-row class="p-4">
            <b-col>
              <ul class="list-group list-group-flush">
                <li
                  v-for="suggestion in row.ListCategorySuggestions"
                  :key="suggestion.id"
                  class="list-group-item"
                >
                  <b-media no-body class="align-items-center">
                    <ProductThumbnail :product="suggestion.products[0]" />
                    <b-media-body>
                      <p class="h5 mb-0">
                        {{ suggestion.products[0].name }}
                      </p>
                    </b-media-body>
                  </b-media>
                </li>
              </ul>
            </b-col>
          </b-row>
        </template>
      </el-table-column>
    </el-table>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductThumbnail from '@/components/Product/ProductThumbnail'
import ItemsList from '@/components/SchoolLists/Items/ItemsList'

export default {
  components: {
    ProductThumbnail,
    ItemsList,
  },
  computed: {
    ...mapGetters('schoolLists/categories', ['sortedCategories']),
  },
  methods: {
    handleCellClassName({ columnIndex }) {
      if (columnIndex === 1 || columnIndex === 2) {
        return 'border-left'
      }
      return ''
    },
  },
}
</script>
