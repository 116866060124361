<template>
  <div :class="`order order--${shippingTypeSlug}`">
    <div class="order-header">
      <div class="order-header__column">
        <h1 class="order-header__title">Commande</h1>
        <div class="order-header__content">
          <div class="order-header__barcode">
            <Barcode :key="order.id" :value="order.id" />
          </div>
          <div class="order-header__label">N°{{ order.id }}</div>
        </div>
      </div>

      <div class="order-header__column">
        <h2 class="order-header__title">Numéro {{ clientNumberLabel }}</h2>
        <div class="order-header__content">
          <div class="order-header__barcode">
            <Barcode :key="order.clientNumber" :value="order.clientNumber" />
          </div>
          <div class="order-header__label">
            {{ order.clientNumber }}
          </div>
        </div>
      </div>

      <div class="order-header__column">
        <div class="order-header__total">
          <div class="order-header__total__count">
            {{ itemCount }}
          </div>
          <div class="order-header__total__label">
            {{ itemCount | pluralize('Item') }}
          </div>
        </div>
      </div>
    </div>

    <div class="order-content">
      <div class="order-content__header">
        <div class="order-date">
          <strong>Date de commande</strong><br />{{
            order.date | dateFormatDefault
          }}
        </div>

        <div class="order-reception">
          <strong>Réception</strong><br />{{ receptionBoxName }}
        </div>

        <div class="order-badge">
          <span class="order-badge__label" v-html="shippingTypeLabel" />
          <span class="order-badge__icon">
            <img :src="shippingTypeIcon" />
          </span>
        </div>
      </div>

      <div class="order-content__details">
        <div class="order-box order-box--billing">
          <div class="order-box__header">
            <h2 class="order-box__title">Facturé à :</h2>
          </div>

          <div class="order-box__content">
            <h3 class="order-box__content__title">
              {{ order.billingAddress.firstName }}
              {{ order.billingAddress.lastName }}
            </h3>
            <div class="order-box__column">
              {{ order.billingAddress.address
              }}<span v-if="order.billingAddress.address2"
                >, {{ order.billingAddress.address2 }}</span
              ><br />
              {{ order.billingAddress.city }}, {{ order.billingAddress.state }},
              {{ order.billingAddress.country }}<br />
              {{ order.billingAddress.postalCode }}
            </div>
            <div class="order-box__column">
              <template v-if="order.phoneNumber">
                Téléphone: {{ order.phoneNumber }}<br />
              </template>
              Courriel: {{ order.email }}
            </div>
          </div>
        </div>

        <div class="order-box order-box--shipping">
          <div class="order-box__header">
            <h2 class="order-box__title">Livraison / Cueillette :</h2>
          </div>

          <div class="order-box__content">
            <h3 v-if="order.pickUp" class="order-box__content__title">
              {{ order.shippingAddress.firstName }}
              {{ order.shippingAddress.lastName }} -
              {{ order.pickUp.name }}
            </h3>

            <div class="order-box__column">
              {{ order.shippingAddress.address
              }}<span v-if="order.shippingAddress.address2"
                >, {{ order.shippingAddress.address2 }}</span
              ><br />
              {{ order.shippingAddress.city }},
              {{ order.shippingAddress.state }},
              {{ order.shippingAddress.country }}<br />
              {{ order.shippingAddress.postalCode }}
            </div>
          </div>
        </div>

        <div
          v-if="schoolLists && schoolLists.length > 0"
          class="order-box order-box--educational"
        >
          <div class="order-box__header">
            <h2 class="order-box__title">Commande scolaire</h2>
          </div>

          <div class="order-box__content">
            <div
              v-for="schoolList in schoolLists"
              :key="schoolList.id"
              class="order-box__inner"
            >
              <h3 class="order-box__content__title">
                {{
                  $t(`establishments.types.${schoolList.Establishment.type}`)
                }}
              </h3>
              <div class="order-box__column">
                {{ schoolList.Establishment.name }} - {{ schoolList.name }}
              </div>
              <div
                v-if="order.childFirstName || order.childLastName"
                class="order-box__column"
              >
                Identifié au nom de
                <span class="font-weight-bold"
                  >{{ order.childFirstName }} {{ order.childLastName }}</span
                >
              </div>
              <div
                v-if="order.businessOrderNumber"
                class="order-box__column"
              >
                Numéro de commande corporative
                <span class="font-weight-bold">{{ order.businessOrderNumber }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="order.payment" class="order-box order-box--payment">
          <div class="order-box__header">
            <h2 class="order-box__title">Paiement</h2>
          </div>

          <div class="order-box__content">
            <h3 class="order-box__content__title">
              {{ order.payment.cardType | capitalize }}
            </h3>

            <div class="order-box__column">
              {{ order.payment.date | dateFormatShort }} |
              <strong>{{
                (parseFloat(order.payment.amount) * 100) | currencyFormat
              }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <table class="order-product-table">
      <thead>
        <tr>
          <th>{{ products.length | pluralize('Produit') }}</th>
          <th>Prix unitaire</th>
          <th>Quantité</th>
          <th>Statut</th>
          <th>Magasin</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="product in products">
          <OrderPrintItem :key="product.id" :item="product" :order="order" />
          <template v-if="product.replacements">
            <OrderPrintItem
              v-for="replacement in product.replacements"
              :key="replacement.id"
              :item="replacement"
              :order="order"
              :replacement="true"
            />
          </template>
        </template>
      </tbody>
    </table>

    <div class="order-summary">
      <ul class="order-summary__list">
        <li class="order-summary__item">
          <span class="order-summary__item__label">Sous-total</span>
          <span class="order-summary__item__value">{{
            order.subTotal | currencyFormat
          }}</span>
        </li>
        <li v-if="order.promoCode" class="order-summary__item">
          <span class="order-summary__item__label">{{ order.promoCode }}</span>
          <span class="order-summary__item__value"
            >- {{ order.promoAmount | currencyFormat }}</span
          >
        </li>
        <li class="order-summary__item">
          <span class="order-summary__item__label"> Livraison </span>
          <span class="order-summary__item__value">
            <template v-if="order.shippingFees">
              {{ order.shippingFees | currencyFormat }}
            </template>
            <template v-else> Gratuite </template>
          </span>
        </li>
        <li v-if="order.handlingFees" class="order-summary__item">
          <span class="order-summary__item__label"> Frais de manutention </span>
          <span class="order-summary__item__value">
            {{ order.handlingFees | currencyFormat }}
          </span>
        </li>
        <li
          v-for="tax in order.taxes"
          :key="tax.code"
          class="order-summary__item"
        >
          <span class="order-summary__item__label">
            {{ tax.code }}
            <template v-if="tax.percentage">
              {{ tax.percentage | percentFormat }}
            </template>
          </span>
          <span class="order-summary__item__value">{{
            tax.total | currencyFormat
          }}</span>
        </li>
        <li v-if="order.refundedAmount" class="order-summary__item">
          <span class="order-summary__item__label"> Montant remboursé </span>
          <span class="order-summary__item__value">
            {{ order.refundedAmount | currencyFormat }}
          </span>
        </li>
        <li v-if="order.refundedShippingFeeAmount" class="order-summary__item">
          <span class="order-summary__item__label">
            Frais de livraison remboursé
          </span>
          <span class="order-summary__item__value">
            {{ order.refundedShippingFeeAmount | currencyFormat }}
          </span>
        </li>
        <li v-if="order.refundedHandlingFeeAmount" class="order-summary__item">
          <span class="order-summary__item__label">
            Frais de manutention remboursé
          </span>
          <span class="order-summary__item__value">
            {{ order.refundedHandlingFeeAmount | currencyFormat }}
          </span>
        </li>
        <li class="order-summary__item">
          <span class="order-summary__item__label">Total</span>
          <span class="order-summary__item__value">{{
            order.total | currencyFormat
          }}</span>
        </li>
      </ul>
    </div>

    <div
      v-if="order.depositAmount && order.balanceAmount"
      class="order-summary"
    >
      <ul class="order-summary__list">
        <li class="order-summary__item">
          <span class="order-summary__item__label">Montant du dépôt</span>
          <span class="order-summary__item__value">{{
            order.depositAmount | currencyFormat
          }}</span>
        </li>
        <li class="order-summary__item">
          <span class="order-summary__item__label">Balance à payer</span>
          <span class="order-summary__item__value">{{
            order.balanceAmount | currencyFormat
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Barcode from '@/components/Barcode'
import OrderPrintItem from '@/components/Orders/OrderPrintItem'

export default {
  components: {
    Barcode,
    OrderPrintItem,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('orders', ['getSingleProducts']),
    products() {
      return this.getSingleProducts(this.orderItems)
    },
    itemCount() {
      return this.orderItems.reduce((acc, item) => acc + item.quantity, 0)
    },
    shippingTypeIcon() {
      switch (this.shippingTypeSlug) {
        case 'cueillette':
          return '/img/icons/cueillette@2x.png'
        case 'livraison':
          return '/img/icons/livraison@2x.png'
        case 'livraison-hors-canada':
          return '/img/icons/livraison-hors-canada@2x.png'

        default:
          return ''
      }
    },

    shippingTypeLabel() {
      switch (this.shippingTypeSlug) {
        case 'cueillette':
          return `Cueillette<br>${this.order.pickUp.establishment} - ${this.order.pickUp.name}`
        case 'livraison':
          return 'Livraison'
        case 'livraison-hors-canada':
          return 'Livraison<br>hors-canada'

        default:
          return ''
      }
    },

    shippingTypeSlug() {
      if (this.order.pickUp) {
        return 'cueillette'
      }
      if (this.order.shippingAddress.country !== 'CA') {
        return 'livraison-hors-canada'
      }
      return 'livraison'
    },

    schoolLists() {
      const schoolLists = this.products
        .filter((product) => product.schoolList)
        .map(({ schoolList }) => JSON.stringify(schoolList))
      const uniqueSchoolLists = [...new Set(schoolLists)]
      return uniqueSchoolLists.map((string) => JSON.parse(string))
    },

    clientNumberLabel() {
      switch (this.order.memberType) {
        case 'MEMBER':
          return 'membre'
        case 'EMPLOYEE':
          return 'membre (Employé)'
        case 'INSTITUTE':
          return 'membre (Institutionnel)'
        case 'BUSINESS':
          return 'membre (Corporatif)'
        case 'LIBRARY':
          return 'membre (Biblio UL)'
        case 'EMERGING_ARTIST':
          return 'membre (Artiste émergent)'
        case 'PROFESSIONAL_ARTIST':
          return 'membre (Artiste professionnel)'
        case 'CJMS':
          return 'membre (CJMS)'
        case 'SPECIAL_1':
          return 'membre (Spécial 1)'
        case 'SPECIAL_2':
          return 'membre (Spécial 2)'
        case 'SPECIAL_3':
          return 'membre (Spécial 3)'
        case 'SPECIAL_4':
          return 'membre (Spécial 4)'
        default:
          return 'client'
      }
    },

    receptionBoxName() {
      return this.order.receptionBox ? this.order.receptionBox.name : 'N/A'
    },
  },
  methods: {
    statusesWithQuantity(statuses) {
      return statuses.filter((status) => status.quantity > 0)
    },
  },
}
</script>

<style lang="sass">
.order
  display: none
</style>

<style scoped>
/* FOR DEBUGGING PURPOSES  */
/* CSS NEEDS TO BE UPDATED IN /public/css/order.print.css  */

.order-header {
  align-items: flex-start;
  border-bottom: solid 1px #001e31;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 3px;
}

.order-header__column {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.order-header__column:first-child {
  flex: auto;
}
.order-header__column:nth-child(2) {
  flex: auto;
}
.order-header__column:last-child {
  flex: none;
}

.order-header__content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}

.order-header__title {
  color: #001e31;
  flex: auto;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 18px;
  margin: 0 0 0 0;
  text-transform: uppercase;
}

.order-header__label {
  color: #001e31;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 25px;
  margin-left: 10px;
}

.order-header__total {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-flow: column wrap;
}

.order-header__total__count {
  align-items: center;
  border-radius: 5px;
  border: solid 1px #001e31;
  display: flex;
  flex-flow: row wrap;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  height: 22px;
  justify-content: flex-end;
  letter-spacing: normal;
  line-height: 15px;
  min-width: 43px;
  padding: 4px 5px;
}

.order-header__total__label {
  color: #001e31;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 16px;
  margin-top: 3px;
  text-align: right;
}

.order-content__header {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-top: 15px;
}

.order-content__details {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 20px;
}

.order-date,
.order-reception {
  color: #004b80;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 15px;
}

.order-date strong,
.order-reception strong {
  text-transform: uppercase;
  font-weight: 600;
}

.order-date {
  justify-self: flex-start;
  flex: auto;
}

.order-reception {
  margin-right: 25px;
  padding-right: 25px;
  border-right: 1px solid #004b80;
  text-align: right;
  flex: none;
}

.order-badge {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex: none;
  justify-content: flex-start;
  max-width: 50%;
}

.order-badge__icon {
  align-items: center;
  border-radius: 32px;
  display: flex;
  flex-flow: row wrap;
  flex: none;
  font-family: 'Inter', sans-serif;
  height: 32px;
  justify-content: center;
  margin-left: 8px;
  width: 32px;
}

.order--cueillette .order-badge__icon {
  background-color: #e9e4d8;
}

.order--cueillette .order-badge__icon img {
  height: 14px;
  width: 12px;
}

.order--livraison .order-badge__icon {
  background-color: #d0eeef;
}

.order--livraison .order-badge__icon img {
  height: 12px;
  width: 17px;
}

.order--livraison-hors-canada .order-badge__icon {
  background-color: #ffb9ac;
}

.order--livraison-hors-canada .order-badge__icon img {
  height: 16px;
  width: 18px;
}

.order-badge__label {
  color: #001e31;
  flex: auto;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  position: relative;
  text-align: right;
  text-transform: uppercase;
  top: 1px;
}

.order-box {
  width: calc(50% - 7.5px);
}

.order-box__content {
  align-items: flex-start;
  border-radius: 5px;
  border: solid 1px #b2b9bb;
  color: #012e4c;
  display: flex;
  flex-flow: row wrap;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  justify-content: space-between;
  letter-spacing: normal;
  line-height: 16px;
  margin-top: 4px;
  padding: 10px 19px 10px 10px;
}

.order-box:last-child {
  flex: auto;
  width: auto;
}

.order-box:nth-child(2) {
  margin-left: 15px;
}

.order-box:nth-child(3) {
  margin-top: 5px;
}

.order-box:nth-child(4) {
  margin-left: 15px;
  margin-top: 5px;
}

.order-box__header {
  align-items: flex-end;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  position: relative;
}

.order-box__title {
  color: #001e31;
  flex: auto;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 14px;
  margin: 0;
  text-transform: uppercase;
}

.order--livraison-hors-canada .order-box--shipping .order-box__content {
  border: dotted 2px #de3112;
}

.order-box__inner {
  width: 100%;
}

.order-box__inner + .order-box__inner {
  margin-top: 10px;
}

.order-box__column {
  width: 45%;
}

.order-box--payment .order-box__column,
.order-box--shipping .order-box__column,
.order-box--educational .order-box__column {
  width: 100%;
}

.order-box__content__title {
  color: #012e4c;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 15px;
  width: 100%;
  margin: 0 0 5px 0;
}

.order-box--educational .order-box__content__title {
  margin: 0;
}

.order-product-table {
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;
}

.order-product-table thead {
  background-color: #f0f3f5;
  border-radius: 5px;
  padding: 7px 10px;
  width: 100%;
}

.order-product-table th {
  color: #001e31;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 13px;
  padding: 7px 10px;
  text-align: left;
  text-transform: uppercase;
}

.order-product-table th:last-child {
  text-align: right;
}

.order-product-table tr {
  border-bottom: 1px solid #b2b9bb;
}

.order-product-table tr.order-product--with-replacement {
  border-bottom: none !important;
}

.order-product-table tr.order-product--with-replacement + tr {
  background-color: #f0f3f5;
}

.order-summary {
  background-color: #f9fafb;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
}

.order-summary__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order-summary__item {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: relative;
}

.order-summary__item + .order-summary__item {
  margin-top: 3px;
}

.order-summary__item__label {
  color: #001e31;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 15px;
}

.order-summary__item__value {
  color: #012e4c;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 15px;
  text-align: right;
}

.order-summary__item:last-child {
  padding-top: 16px;
}

.order-summary__item:last-child .order-summary__item__label {
  color: #008085;
  font-weight: bold;
}

.order-summary__item:last-child .order-summary__item__value {
  font-size: 15px;
}

.order-summary__item:last-child ::after {
  background-color: #b2b9bb;
  content: '';
  display: block;
  height: 1px;
  left: 0px;
  position: absolute;
  top: 8px;
  width: 100%;
}

.font-weight-bold {
  font-weight: 700;
}

.text-nowrap {
  white-space: nowrap !important;
}
</style>
